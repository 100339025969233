import { ParseObject } from './parse-object';

export class PaymentMethod extends ParseObject {
  public static readonly CLASS_NAME = 'PaymentMethod';
  public static readonly NAME_KEY = 'name';
  public static readonly CODE_KEY = 'code';
  public static readonly ICON_KEY = 'icon';

  constructor() {
    super(PaymentMethod.CLASS_NAME);
  }

  public get name(): string {
    return this.get(PaymentMethod.NAME_KEY);
  }

  public set name(name: string) {
    this.set(PaymentMethod.NAME_KEY, name);
  }

  public get code(): string {
    return this.get(PaymentMethod.CODE_KEY);
  }

  public set code(code: string) {
    this.set(PaymentMethod.CODE_KEY, code);
  }

  public get icon(): Parse.File {
    return this.get(PaymentMethod.ICON_KEY);
  }

  public set icon(icon: Parse.File) {
    this.set(PaymentMethod.ICON_KEY, icon);
  }
}

Parse.Object.registerSubclass(PaymentMethod.CLASS_NAME, PaymentMethod);
