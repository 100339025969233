import { ParseObject } from './parse-object';
import { Product } from './product';
import { User } from './user';

export class ProductView extends ParseObject {
  public static readonly CLASS_NAME = 'ProductView';
  public static readonly OWNER_KEY = 'owner';
  public static readonly VIEWS_KEY = 'views';
  public static readonly PRODUCT_KEY = 'product';

  constructor() {
    super(ProductView.CLASS_NAME);
  }

  public get views(): number {
    return this.get(ProductView.VIEWS_KEY);
  }

  public set views(views: number) {
    this.set(ProductView.VIEWS_KEY, views);
  }

  public get owner(): User {
    return this.get(ProductView.VIEWS_KEY);
  }

  public set owner(owner: User) {
    this.set(ProductView.VIEWS_KEY, owner);
  }

  public get product(): Product {
    return this.get(ProductView.PRODUCT_KEY);
  }

  public set product(product: Product) {
    this.set(ProductView.PRODUCT_KEY, product);
  }
}

Parse.Object.registerSubclass(ProductView.CLASS_NAME, ProductView);
