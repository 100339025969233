import { ParseQuery } from './parse-query';
import { Accessory, ProductType, Product } from './../data';

import './../extensions/reg-exp+escape';

export class AccessoryQuery extends ParseQuery<Accessory> {
  constructor() {
    super(Accessory);
  }

  public mainProduct(id: string) {
    this.equalTo(Accessory.MAIN_PRODUCT_KEY, id);
    return this;
  }

  public accessoryProduct(id: string) {
    this.equalTo(Accessory.ACCESSORY_PRODUCT_KEY, id);
    return this;
  }

  public related(id: string, type: string) {
    if (type == ProductType.MAIN) {
      this.equalTo(Accessory.MAIN_PRODUCT_KEY, new Product(id));
    } else {
      this.equalTo(Accessory.ACCESSORY_PRODUCT_KEY, new Product(id));
    }

    return this
  }
}
