import { Injectable } from '@angular/core';
import { Observable, Observer, BehaviorSubject, of } from 'rxjs';

import { UserService } from '@shared/services';
import { Address, User } from './../data';
import { AddressQuery } from './../queries';
import { first, publishReplay, refCount, switchMap } from 'rxjs/operators';

declare var FB;

@Injectable()
export class AddressService {
  private _myAddresses: Address[];
  private _myAddressesSubject: BehaviorSubject<Address[]>;
  private _defaultAddress: Observable<Address>;

  constructor(private userService: UserService) {
    this._myAddresses = [];
    this._myAddressesSubject = new BehaviorSubject([]);
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  public addMyAddress(address: Address): Observable<Address> {
    let user = User.current();

    address.user = user;

    this._myAddresses.push(address);
    this._myAddressesSubject.next(this._myAddresses);

    let result = address.rx().save().pipe(publishReplay(1)).pipe(refCount());

    result.pipe(first()).subscribe(value => {
      if (!user.defaultAddress) {
        this.setDefaultAddress(address).pipe(first()).subscribe();
      }

      this.fetchMyAddresses();
    });

    return result;
  }

  public deleteMyAddress(address: Address): Observable<Address> {
    let result = address.rx().destroy().pipe(publishReplay(1)).pipe(refCount());

    let user = User.current();
    let defaultAddress = user.defaultAddress;
    let index = this._myAddresses.indexOf(address);

    this._myAddresses.splice(index, 1);
    this._myAddressesSubject.next(this._myAddresses);

    if (defaultAddress && defaultAddress.id == address.id) {
      user.defaultAddress = null;
      this.userService.save(user).pipe(first()).subscribe();
    }

    result.pipe(first()).subscribe(() => this.fetchMyAddresses());

    return result;
  }

  public myAddresses() {
    this.fetchMyAddresses();
    return this._myAddressesSubject.asObservable().pipe(publishReplay(1)).pipe(refCount());
  }

  public defaultAddress() {
    if (!this._defaultAddress) {
      this._defaultAddress = this.userService.user
        .pipe(switchMap(o => {
          return o && o.defaultAddress ? o.defaultAddress.rx().fetch() : of(null);
        })).pipe(publishReplay(1)).pipe(refCount());
    }
    return this._defaultAddress;
  }

  public setDefaultAddress(address: Address) {
    let user = User.current();

    user.defaultAddress = address;

    return this.userService.save(user);
  }

  // ===============================================================================================
  // Private Methods
  // ===============================================================================================

  private fetchMyAddresses() {
    let user = User.current();
    let query = new AddressQuery();
    let current = this._myAddresses;

    query.user(user);

    query.find().then(
      result => {
        this._myAddresses = result;
        this._myAddressesSubject.next(result);
      }
    )
  }
}
