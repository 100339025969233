import { ParseObject } from './parse-object';

export class Subscription extends ParseObject {
  public static readonly CLASS_NAME = 'Subscription';
  public static readonly EMAIL_KEY = 'email';

  constructor() {
    super(Subscription.CLASS_NAME);
  }

  public get email(): string {
    return this.get(Subscription.EMAIL_KEY);
  }

  public set email(email: string) {
    this.set(Subscription.EMAIL_KEY, email);
  }
}

Parse.Object.registerSubclass(Subscription.CLASS_NAME, Subscription);
