import { ParseObject } from './parse-object';
import { Serializable } from './serializable';

import * as _ from 'lodash';

import { User } from './user';
import { EnquiryItem } from './enquiry-item';
import { BusinessCategory } from './business-category';

export class Enquiry extends ParseObject implements Serializable<Enquiry> {
  public static readonly CLASS_NAME = 'Enquiry';
  public static readonly SUBMITTED_KEY = 'submitted';
  public static readonly ITEMS_KEY = 'items';
  public static readonly USER_KEY = 'user';
  public static readonly CUSTOMER_NAME_KEY = 'customerName';
  public static readonly EMAIL_KEY = 'email';
  public static readonly PHONE_NUMBER_KEY = 'phoneNumber';
  public static readonly SUBJECT_KEY = 'subject';
  public static readonly MESSAGE_KEY = 'message';
  public static readonly PROJECT_NAME_KEY = 'projectName';
  public static readonly BUSINESS_CATEGORY_KEY = 'category';
  public static readonly COMPANY_KEY = 'company';
  
  constructor() {
    super(Enquiry.CLASS_NAME);
    this.items = [];
  }

  // ===============================================================================================
  // Override Methods
  // ===============================================================================================

  public serialize() {
    return {
      items: this.items.map(o => o.serialize())
    }
  }

  public deserialize(json: any) {
    let o = new Enquiry();
    o.items = json['items'] ? json['items'].map(i => new EnquiryItem().deserialize(i)) : [];
    return o;
  }

  // ===============================================================================================
  // Accessors
  // ===============================================================================================

  public get customerName() {
    return this.get(Enquiry.CUSTOMER_NAME_KEY);
  }

  public set customerName(customerName: string) {
    this.set(Enquiry.CUSTOMER_NAME_KEY, customerName);
  }

  public get email() {
    return this.get(Enquiry.EMAIL_KEY);
  }

  public set email(email: string) {
    this.set(Enquiry.EMAIL_KEY, email);
  }

  public get phoneNumber() {
    return this.get(Enquiry.PHONE_NUMBER_KEY);
  }

  public set phoneNumber(phoneNumber: string) {
    this.set(Enquiry.PHONE_NUMBER_KEY, phoneNumber);
  }

  public get subject() {
    return this.get(Enquiry.SUBJECT_KEY);
  }

  public set subject(subject: string) {
    this.set(Enquiry.SUBJECT_KEY, subject);
  }

  public get message() {
    return this.get(Enquiry.MESSAGE_KEY);
  }

  public set message(message: string) {
    this.set(Enquiry.MESSAGE_KEY, message);
  }

  public get submitted() {
    return this.get(Enquiry.SUBMITTED_KEY);
  }

  public set submitted(submitted: string) {
    this.set(Enquiry.SUBMITTED_KEY, submitted);
  }

  public get user() {
    return this.get(Enquiry.USER_KEY);
  }

  public set user(user: User) {
    this.set(Enquiry.USER_KEY, user);
  }

  public get items() {
    return this.get(Enquiry.ITEMS_KEY);
  }

  public set items(items: EnquiryItem[]) {
    this.set(Enquiry.ITEMS_KEY, items);
  }

  public get businessCategory() {
    return this.get(Enquiry.BUSINESS_CATEGORY_KEY);
  }

  public set businessCategory(businessCategory: BusinessCategory) {
    this.set(Enquiry.BUSINESS_CATEGORY_KEY, businessCategory);
  }

  public get company() {
    return this.get(Enquiry.COMPANY_KEY);
  }

  public set company(company: string) {
    this.set(Enquiry.COMPANY_KEY, company);
  }

  public get projectName() {
    return this.get(Enquiry.PROJECT_NAME_KEY);
  }

  public set projectName(projectName: string) {
    this.set(Enquiry.PROJECT_NAME_KEY, projectName);
  }

  public get total(): number {
    return _.sumBy(this.items, o => o.totalPrice);
  }
}

Parse.Object.registerSubclass(Enquiry.CLASS_NAME, Enquiry);
