import { ParseObject } from './parse-object'

import * as _ from 'lodash'

export class ProductInputUnit extends ParseObject {
  public static readonly CLASS_NAME = 'ProductInputUnit'
  public static readonly NAME_KEY = 'name'

  constructor() {
    super(ProductInputUnit.CLASS_NAME)
  }

  static create() {
    const input = new ProductInputUnit()

    input.name = ''

    return input
  }

  public get name(): string {
    return this.get(ProductInputUnit.NAME_KEY)
  }

  public set name(name: string) {
    this.set(ProductInputUnit.NAME_KEY, name)
  }  
}

Parse.Object.registerSubclass(ProductInputUnit.CLASS_NAME, ProductInputUnit)

export class ProductInput extends ParseObject {
  public static readonly CLASS_NAME = 'ProductInput'
  public static readonly NAME_KEY = 'name'
  public static readonly UNITS_KEY = 'units'

  constructor() {
    super(ProductInput.CLASS_NAME)
  }

  static create() {
    const input = new ProductInput()

    input.name = ''
    input.units = []

    return input
  }

  public get name(): string {
    return this.get(ProductInput.NAME_KEY)
  }

  public set name(name: string) {
    this.set(ProductInput.NAME_KEY, name)
  }

  public get units(): ProductInputUnit[] {
    return this.get(ProductInput.UNITS_KEY)
  }

  public set units(units: ProductInputUnit[]) {
    this.set(ProductInput.UNITS_KEY, units)
  }

  public get displayUnits() {
    return this.units.map(o => o.name).join(', ')
  }

  public entries() {
    return _.concat<ParseObject>(this.units, this);
  }
}

Parse.Object.registerSubclass(ProductInput.CLASS_NAME, ProductInput)
