import { Injectable } from '@angular/core';

import { BehaviorSubject, from, Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';
import { Category } from '../data';

@Injectable()
export class FeatureProductService {
  private _subject: BehaviorSubject<any>
  constructor() {

  }

  public async find(category: Category) {
    return await Parse.Cloud.run('feature-product', { categoryId: category.id })
  }
}
