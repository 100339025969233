import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

import { Observable, Observer, Subject } from 'rxjs';

import { Order, ShoppingCartItem, CheckoutDto, CheckoutItemDto, IAddress } from '@shared/data';
import { CheckoutService } from '@shared/services';

const CHECK_OUT = 'cod-checkout';

@Injectable()
export class CashOnDeliveryService {
  constructor(private modalService: NgbModal, private checkoutService: CheckoutService) {

  }

  public checkout(checkoutDto: CheckoutDto) {
    return Parse.Cloud.run(CHECK_OUT, checkoutDto);
  }
}
