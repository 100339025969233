import { ParseQuery } from './parse-query';

import './../extensions/reg-exp+escape';

import { Category } from './../data';

export class CategoryQuery extends ParseQuery<Category> {
  constructor() {
    super(Category);
  }

  public search(searchText: string) {
    this.matches(Category.NAME_KEY, RegExp.escape(searchText), 'i');
    return this;
  }
}