import { ParseObject } from './parse-object';
import { Order } from './order';
import { Product } from './product';

export class OrderProduct extends ParseObject {
  public static readonly CLASS_NAME = 'OrderProduct';
  public static readonly ORDER_KEY = 'order';
  public static readonly PRODUCT_KEY = 'product';
  public static readonly PRODUCT_NAME_KEY = 'productName';
  public static readonly QUANTITY_KEY = 'quantity';
  public static readonly UNIT_PRICE_KEY = 'unitPrice';
  public static readonly FINAL_PRICE_KEY = 'finalPrice';

  constructor() {
    super(OrderProduct.CLASS_NAME);
  }

  public get order(): Order {
    return this.get(OrderProduct.ORDER_KEY);
  }

  public set order(order: Order) {
    this.set(OrderProduct.ORDER_KEY, order);
  }

  public get product(): Product {
    return this.get(OrderProduct.PRODUCT_KEY);
  }

  public set product(product: Product) {
    this.set(OrderProduct.PRODUCT_KEY, product);
  }

  public get productName(): string {
    return this.get(OrderProduct.PRODUCT_NAME_KEY);
  }

  public set productName(productName: string) {
    this.set(OrderProduct.PRODUCT_NAME_KEY, productName);
  }

  public get quantity(): number {
    return this.get(OrderProduct.QUANTITY_KEY);
  }

  public set quantity(quantity: number) {
    this.set(OrderProduct.QUANTITY_KEY, quantity);
    this.calculateFinalPrice();
  }

  public get unitPrice(): number {
    return this.get(OrderProduct.UNIT_PRICE_KEY);
  }

  public set unitPrice(unitPrice: number) {
    this.set(OrderProduct.UNIT_PRICE_KEY, unitPrice);
    this.calculateFinalPrice();
  }

  public get finalPrice(): number {
    return this.get(OrderProduct.FINAL_PRICE_KEY);
  }

  public set finalPrice(finalPrice: number) {
    this.set(OrderProduct.FINAL_PRICE_KEY, finalPrice);
  }

  public revert(): void {
    super.revert();

    if (this.order) {
      this.order.revert();
    }

    if (this.product) {
      this.product.revert();
    }
  }

  // - Private methods
  private calculateFinalPrice() {
    this.finalPrice = this.unitPrice * this.quantity;
  }
}

Parse.Object.registerSubclass(OrderProduct.CLASS_NAME, OrderProduct);
