import { ParseObject } from './parse-object'
import { FileRef } from './file-ref'

export class SerialNumberInfo extends ParseObject {
  public static readonly CLASS_NAME = 'SerialNumberInfo'
  public static readonly IMAGES_KEY = 'images'
  public static readonly SERIAL_NUMBER_KEY = 'serialNumbers'

  constructor() {
    super(SerialNumberInfo.CLASS_NAME)
  }

  get images(): FileRef[] {
    return this.get(SerialNumberInfo.IMAGES_KEY)
  }

  set images(images: FileRef[]) {
    this.set(SerialNumberInfo.IMAGES_KEY, images)
  }

  get serialNumbers(): string[] {
    return this.get(SerialNumberInfo.SERIAL_NUMBER_KEY)
  }

  set serialNumbers(serialNumbers: string[]) {
    this.set(SerialNumberInfo.SERIAL_NUMBER_KEY, serialNumbers)
  }
}

Parse.Object.registerSubclass(SerialNumberInfo.CLASS_NAME, SerialNumberInfo)
