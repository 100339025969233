import { Cascade } from './cascade';

import * as _ from 'lodash';

import { Category } from './category';
import { FileRef } from './file-ref';
import { Size } from './size';

export class Attachment extends Cascade {
  public static readonly CLASS_NAME = 'Attachment';
  public static readonly TITLE_KEY = 'title';
  public static readonly TYPE_KEY = 'type';
  public static readonly IMAGE_KEY = 'image';
  public static readonly FILE_KEY = 'file';
  public static readonly EXTERNAL_URL_KEY = 'externalUrl';
  public static readonly CATEGORIES_KEY = 'categories';
  public static readonly VIEWS_KEY = 'views';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly SHOW_AT_HOME_KEY = 'showAtHome';
  public static readonly EXTENSION_KEY = 'extension';

  public static readonly CCT_KEY = 'cct';
  public static readonly WATTAGE_KEY = 'wattage';

  public static readonly TYPE_TECHNICAL_BOOK = 'technical_book';
  public static readonly TYPE_3D_DRAWING = '3d_drawing';
  public static readonly TYPE_VIDEO = 'video';
  public static readonly TYPE_CERTIFICATE = 'certificate';
  public static readonly TYPE_INSTALLATION = 'installation';
  public static readonly TYPE_IES = 'ies';

  public static readonly IMAGE_SIZE: Size = { w: 100, h: 100 };

  constructor(type: string) {
    super(Attachment.CLASS_NAME);
    this.type = type;
  }

  public get showAtHome(): string {
    return this.get(Attachment.SHOW_AT_HOME_KEY);
  }

  public set showAtHome(showAtHome: string) {
    this.set(Attachment.SHOW_AT_HOME_KEY, showAtHome);
  }

  public get title(): string {
    return this.get(Attachment.TITLE_KEY);
  }

  public set title(title: string) {
    this.set(Attachment.TITLE_KEY, title);
  }

  public get cct(): number {
    return this.get(Attachment.CCT_KEY);
  }

  public set cct(cct: number) {
    this.set(Attachment.CCT_KEY, cct);
  }

  public set wattage(wattage: number) {
    this.set(Attachment.WATTAGE_KEY, wattage);
  }

  public get wattage(): number {
    return this.get(Attachment.WATTAGE_KEY);
  }

  public get type(): string {
    return this.get(Attachment.TYPE_KEY);
  }

  public set type(type: string) {
    this.set(Attachment.TYPE_KEY, type);
  }

  public get image(): FileRef {
    return this.get(Attachment.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(Attachment.IMAGE_KEY, image);
  }

  public get imageUrl(): string {
    return this.image && this.image.url ? this.image.url : ''
  }

  public get file(): FileRef {
    return this.get(Attachment.FILE_KEY);
  }

  public set file(file: FileRef) {
    this.extension = file.extension;
    this.set(Attachment.FILE_KEY, file);
  }

  public get externalUrl(): string {
    return this.get(Attachment.EXTERNAL_URL_KEY);
  }

  public set externalUrl(externalUrl: string) {
    this.set(Attachment.EXTERNAL_URL_KEY, externalUrl);
  }

  public get categories(): Category[] {
    return this.get(Attachment.CATEGORIES_KEY);
  }

  public set categories(categories: Category[]) {
    this.set(Attachment.CATEGORIES_KEY, categories);
  }

  public get views(): number {
    return this.get(Attachment.VIEWS_KEY);
  }

  public set views(views: number) {
    this.set(Attachment.VIEWS_KEY, views);
  }

  public get sortOrder(): number {
    return this.get(Attachment.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(Attachment.SORT_ORDER_KEY, sortOrder);
  }

  public get extension(): string {
    return this.get(Attachment.EXTENSION_KEY);
  }

  public set extension(extension: string) {
    this.set(Attachment.EXTENSION_KEY, extension);
  }

  public get imageSize(): Size {
    return Attachment.IMAGE_SIZE;
  }

  public entries() {
    return _.compact([this, this.file, this.image]);
  }
}

Parse.Object.registerSubclass(Attachment.CLASS_NAME, Attachment);
