import { ProductSnapshot, ProductVariantSnapshot, User } from '@shared/data';
import { ParseObject } from './parse-object';
import { Address } from './address';

export class DeliveryItem extends ParseObject {
  public static readonly CLASS_NAME = 'DeliveryItem';
  public static readonly QUANTITY_KEY = 'quantity';
  public static readonly PRODUCT_SNAPSHOT_KEY = 'productSnapshot';
  public static readonly VARIANT_SNAPSHOT_KEY = 'variantSnapshot';
  public static readonly DELIVERY_USER_KEY = 'deliveryUser';

  constructor() {
    super(DeliveryItem.CLASS_NAME);
  }

  public get deliveryUser(): User {
    return this.get(DeliveryItem.DELIVERY_USER_KEY);
  }

  public set deliveryUser(user: User) {
    this.set(DeliveryItem.DELIVERY_USER_KEY, user);
  }

  public get quantity(): number {
    return this.get(DeliveryItem.QUANTITY_KEY);
  }

  public set quantity(quantity: number) {
    this.set(DeliveryItem.QUANTITY_KEY, quantity);
  }

  public get productSnapshot() {
    return this.get(DeliveryItem.PRODUCT_SNAPSHOT_KEY);
  }

  public set productSnapshot(productSnapshot: ProductSnapshot) {
    this.set(DeliveryItem.PRODUCT_SNAPSHOT_KEY, productSnapshot);
  }

  public get variantSnapshot() {
    return this.get(DeliveryItem.VARIANT_SNAPSHOT_KEY);
  }

  public set variantSnapshot(variantSnapshot: ProductVariantSnapshot) {
    this.set(DeliveryItem.VARIANT_SNAPSHOT_KEY, variantSnapshot);
  }
}

Parse.Object.registerSubclass(DeliveryItem.CLASS_NAME, DeliveryItem);
