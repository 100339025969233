import { ProductVariant } from './product-variant';

export class ProductVariantSnapshot {
  public price: number;
  public properties: any;

  constructor(variant: ProductVariant) {
    this.price = variant.price;
    this.properties = variant.properties;
  }
}