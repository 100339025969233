import { ParseQuery } from './parse-query';
import { Role, User } from '@shared/data';

export class RoleQuery extends ParseQuery<Role> {
  constructor(roleId?: string) {
    super(Role.CLASS_NAME);

    if (roleId) {
      this.equalTo('objectId', roleId);
    }
  }

  public name(name: string) {
    this.equalTo(Role.NAME_KEY, name);
    return this;
  }

  public adminRole() {
    this.equalTo(Role.NAME_KEY, 'role_admin');
    return this;
  }

  public teamLeaderRole() {
    this.equalTo(Role.NAME_KEY, 'role_team_leader');
    return this;
  }

  public couponDistributorRole() {
    this.equalTo(Role.NAME_KEY, 'role_coupon_distributor');
    return this;
  }
}