import { ParseObject } from './parse-object';

export class Notification extends ParseObject {
  public static readonly CLASS_NAME = 'Notification';
  public static readonly DESC_KEY = 'desc';
  public static readonly TARGET_CLASS_KEY = 'targetClass';
  public static readonly TARGET_ID_KEY = 'targetId';
  public static readonly READ_KEY = 'read';

  public static readonly ORDER_TARGET = 'order';

  constructor() {
    super(Notification.CLASS_NAME);
  }

  public get desc(): string {
    return this.get(Notification.DESC_KEY);
  }

  public set desc(desc: string) {
    this.set(Notification.DESC_KEY, desc);
  }

  public get targetClass(): string {
    return this.get(Notification.TARGET_CLASS_KEY);
  }

  public set targetClass(targetClass: string) {
    this.set(Notification.TARGET_CLASS_KEY, targetClass);
  }

  public get targetId(): string {
    return this.get(Notification.TARGET_ID_KEY);
  }

  public set targetId(targetId: string) {
    this.set(Notification.TARGET_ID_KEY, targetId);
  }

  public get read(): boolean {
    return this.get(Notification.READ_KEY);
  }

  public set read(read: boolean) {
    this.set(Notification.READ_KEY, read);
  }
}

Parse.Object.registerSubclass(Notification.CLASS_NAME, Notification);