import { ParseObject } from './parse-object';
import { Serializable } from './serializable';

import * as _ from 'lodash';

import { User } from './user';
import { ShoppingCartItem } from './shopping-cart-item';

export class ShoppingCart extends ParseObject implements Serializable<ShoppingCart> {
  public static readonly CLASS_NAME = 'ShoppingCart';
  public static readonly ITEMS_KEY = 'items';
  public static readonly USER_KEY = 'user';

  constructor() {
    super(ShoppingCart.CLASS_NAME);
    this.items = [];
  }

  // ===============================================================================================
  // Override Methods
  // ===============================================================================================

  public serialize() {
    return {
      items: this.items.map(o => o.serialize())
    }
  }

  public deserialize(json: any) {
    let o = new ShoppingCart();
    o.items = json['items'] ? json['items'].map(i => new ShoppingCartItem().deserialize(i)) : [];
    return o;
  }

  // ===============================================================================================
  // Accessors
  // ===============================================================================================

  public get user() {
    return this.get(ShoppingCart.USER_KEY);
  }

  public set user(user: User) {
    this.set(ShoppingCart.USER_KEY, user);
  }

  public get items() {
    return this.get(ShoppingCart.ITEMS_KEY);
  }

  public set items(items: ShoppingCartItem[]) {
    this.set(ShoppingCart.ITEMS_KEY, items);
  }
}

Parse.Object.registerSubclass(ShoppingCart.CLASS_NAME, ShoppingCart);
