import { ParseObservable } from './parse-observable';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { Observable, Observer, BehaviorSubject, of } from 'rxjs';

import { User, Role } from './../data';

import { UserQuery } from './../queries';
import { flatMap, publishReplay, refCount, tap } from 'rxjs/operators';

declare var FB;

@Injectable()
export class UserService {
  public static readonly GET_ROLE_NAMES = 'user-get-role-names';
  public static readonly GET_LOWER_ROLE_NAMES = 'user-get-lower-role-names';

  public user: Observable<User>;

  private userSubject: BehaviorSubject<User>;

  constructor() {
    this.userSubject = new BehaviorSubject(User.current());
    this.user = this.userSubject.asObservable();
  }

  public fetch(): Observable<User> {
    return User.current().rx().fetch().pipe(
        publishReplay(1)).pipe(
        refCount()).pipe(
        tap((user) => this.userSubject.next(user)));
  }

  public save(user: User): Observable<User> {
    return user.rx().save().pipe(
    publishReplay(1)).pipe(
    refCount()).pipe(
    tap((user) => this.userSubject.next(user)));
  }

  public login(username: string, password: string): Observable<Parse.User> {
    return new ParseObservable(Parse.User.logIn(username, password)).pipe(
      publishReplay(1)).pipe(
      refCount()).pipe(
      tap(() => this.userSubject.next(User.current())))
  }

  public signup(username: string, password: string, att: any): Observable<Parse.User> {
    return new ParseObservable(Parse.User.signUp(username, password, att)).pipe(
      publishReplay(1)).pipe(
      refCount()).pipe(
      tap((user) => this.userSubject.next(User.current())));
  }

  public resetPassword(email: string): Observable<Parse.User> {
    return new ParseObservable(Parse.User.requestPasswordReset(email)).pipe(
      publishReplay(1)).pipe(
      refCount())
  }

  public fbLogin(): Observable<Parse.User> {
    return this.fbLoginObservable().pipe(flatMap(user => {
      if (!user.existed()) {
        return this.fbMe(user).pipe(tap((user) => this.userSubject.next(user as User)));
      }
      return of(user).pipe(tap((user) => this.userSubject.next(user as User)));
    }));
  }

  public logout(): Observable<any> {
    return new ParseObservable(Parse.User.logOut()).pipe(
      publishReplay(1)).pipe(
      refCount()).pipe(
      tap((user) => this.userSubject.next(null)));
  }

  public getRoleNames() {
    return Parse.Cloud.run(UserService.GET_ROLE_NAMES);
  }

  public getLowerRoleNames() {
    return Parse.Cloud.run(UserService.GET_LOWER_ROLE_NAMES);
  }

  private fbLoginObservable(): Observable<Parse.User> {
    let observable = new Observable<Parse.User>((observer: Observer<any>) => {
      Parse.FacebookUtils.logIn('email', {
        success: (user: Parse.User) => {
          observer.next(user);
          observer.complete();
        },
        error: (user, error) => {
          observer.error(error);
        }
      });
    });
    return observable;
  }

  private fbMe(user: Parse.User): Observable<Parse.User> {
    const accessToken = user.get('authData')['facebook']['access_token']

    return new Observable((observer: Observer<any>) => {
      FB.api('/me?access_token=' + accessToken + '&fields=first_name,last_name,email', (me) => {
        user.save({firstName: me['first_name'], lastName: me['last_name'], email: me['email']}).then(
          (user) => {
            observer.next(user);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      });
    });
  }
}
