import { ParseQuery } from './parse-query';

import './../extensions/reg-exp+escape';

import { Subscription } from './../data/subscription';

export class SubscriptionQuery extends ParseQuery<Subscription> {
  constructor() {
    super(Subscription);
  }

  public search(searchText: string) {
    this.matches(Subscription.EMAIL_KEY, RegExp.escape(searchText), 'i');
    return this;
  }
}