import { ParseObject } from './parse-object';

export class PaymentStatus extends ParseObject {
  public static readonly CLASS_NAME = 'PaymentStatus';
  public static readonly NAME_KEY = 'name';
  public static readonly CODE_KEY = 'code';
  public static readonly COLOR_KEY = 'color';

  public static readonly PAID_STATUS = 'paid';
  public static readonly UNPAID_STATUS = 'unpaid';

  constructor() {
    super(PaymentStatus.CLASS_NAME);
  }

  public get name(): string {
    return this.get(PaymentStatus.NAME_KEY);
  }

  public set name(name: string) {
    this.set(PaymentStatus.NAME_KEY, name);
  }

  public get code(): string {
    return this.get(PaymentStatus.CODE_KEY);
  }

  public set code(code: string) {
    this.set(PaymentStatus.CODE_KEY, code);
  }

  public get color(): string {
    return this.get(PaymentStatus.COLOR_KEY);
  }
}

Parse.Object.registerSubclass(PaymentStatus.CLASS_NAME, PaymentStatus);
