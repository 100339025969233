export interface EnquiryProductDto {
  id: string;
  name: string;
}

export interface EnquiryVariantDto {
  id: string;
}

export interface EnquiryItemDto {
  variant: EnquiryVariantDto;
  product: EnquiryProductDto;
  quantity: number;
  inputOptions: any[];
}

export class SubmitEnquiryDto {
  items: EnquiryItemDto[]
  customerName: string
  company: string
  projectName: string
  businessCategoryId: string
  email: string
  phoneNumber: string
  subject: string
  message: string
}

