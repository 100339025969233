import { User } from './user';
import { ParseObject } from './parse-object';

export interface IAddress {
  contactName: string;
  phone: string;
  address: string;
  district: string;
  city: string;
  country: string;
}

export class Address extends ParseObject implements IAddress {
  public static readonly CLASS_NAME = 'Address';
  public static readonly CONTACT_NAME_KEY = 'contactName';
  public static readonly PHONE_KEY = 'phone';
  public static readonly ADDRESS_KEY = 'address';
  public static readonly DISTRICT_KEY = 'district';
  public static readonly CITY_KEY = 'city';
  public static readonly COUNTRY_KEY = 'country';
  public static readonly USER_KEY = 'user';

  public static readonly COUNTRY_CAMBODIA = 'Cambodia';

  constructor() {
    super(Address.CLASS_NAME);
    this.country = Address.COUNTRY_CAMBODIA;
  }

  public get contactName(): string {
    return this.get(Address.CONTACT_NAME_KEY);
  }

  public set contactName(contactName: string) {
    this.set(Address.CONTACT_NAME_KEY, contactName);
  }

  public get phone(): string {
    return this.get(Address.PHONE_KEY);
  }

  public set phone(phone: string) {
    this.set(Address.PHONE_KEY, phone);
  }

  public get address(): string {
    return this.get(Address.ADDRESS_KEY);
  }

  public set address(address: string) {
    this.set(Address.ADDRESS_KEY, address);
  }

  public get district(): string {
    return this.get(Address.DISTRICT_KEY);
  }

  public set district(district: string) {
    this.set(Address.DISTRICT_KEY, district);
  }

  public get city(): string {
    return this.get(Address.CITY_KEY);
  }

  public set city(city: string) {
    this.set(Address.CITY_KEY, city);
  }

  public get country(): string {
    return this.get(Address.COUNTRY_KEY);
  }

  public set country(country: string) {
    this.set(Address.COUNTRY_KEY, country);
  }

  public get user(): User {
    return this.get(Address.USER_KEY);
  }

  public set user(user: User) {
    this.set(Address.USER_KEY, user);
  }

  public toPlainAddress(): IAddress {
    return {
      contactName: this.contactName,
      phone: this.phone,
      address: this.address,
      district: this.district,
      city: this.city,
      country: this.country
    }
  }
}

Parse.Object.registerSubclass(Address.CLASS_NAME, Address);
