import { ParseObject } from './parse-object';
import * as _ from 'lodash';

import { Cascade } from './cascade';
import { FileRef } from './file-ref';
import { Size } from './size';
import { Attachment } from './attachment';

export class AttachmentGroup extends Cascade {
  public static readonly CLASS_NAME = 'AttachmentGroup';
  public static readonly NAME_KEY = 'name';
  public static readonly TYPE_KEY = 'type';
  public static readonly ATTACHMENTS_KEY = 'attachments';
  public static readonly SORT_ORDER_KEY = 'sortOrder';

  public static readonly TYPE_TECHNICAL_BOOK = 'technical_book';
  public static readonly TYPE_VIDEO = 'video';

  constructor(id?: string) {
    super(AttachmentGroup.CLASS_NAME);
    this.id = id;
  }

  public get name(): string {
    return this.get(AttachmentGroup.NAME_KEY);
  }

  public set name(name: string) {
    this.set(AttachmentGroup.NAME_KEY, name);
  }

  public get type(): string {
    return this.get(AttachmentGroup.TYPE_KEY);
  }

  public set type(type: string) {
    this.set(AttachmentGroup.TYPE_KEY, type);
  }

  public get attachments(): Attachment[] {
    return this.get(AttachmentGroup.ATTACHMENTS_KEY);
  }

  public set attachments(attachments: Attachment[]) {
    this.set(AttachmentGroup.ATTACHMENTS_KEY, attachments);
  }

  public get sortOrder(): number {
    return this.get(AttachmentGroup.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(AttachmentGroup.SORT_ORDER_KEY, sortOrder);
  }

  public entries() {
    let attachments = this.attachments ? _.flatten(this.attachments.map(a => a.entries())) : [];
    return _.concat<ParseObject>(attachments, this);
  }
}

Parse.Object.registerSubclass(AttachmentGroup.CLASS_NAME, AttachmentGroup);
