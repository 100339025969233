import { Cascade } from './cascade';

import * as _ from 'lodash';

import { PageElement } from './page-element';
import { FileRef } from './file-ref';
import { MetaTags } from './meta-tags';

export class Page extends Cascade {
  public static readonly CLASS_NAME = 'Page';
  public static readonly TITLE_KEY = 'title';
  public static readonly SUBTITLE_KEY = 'subtitle';
  public static readonly IMAGE_KEY = 'image';
  public static readonly CONTENT_KEY = 'content';
  public static readonly ELEMENTS_KEY = 'elements';
  public static readonly PERMALINK_KEY = 'permalink';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly METATAGS_KEY = 'metaTags';

  public static readonly PERMALINK_ABOUT_US = 'about-us';
  public static readonly PERMALINK_CONTACT_US = 'contact-us';
  public static readonly PERMALINK_BUYER_GUIDE = 'buyer-guide';
  public static readonly PERMALINK_DELIVERY_POLICY = 'delivery-policy';
  public static readonly PERMALINK_ORDER_TRACKING = 'order-tracking';
  public static readonly PERMALINK_RETURN_POLICY = 'return-policy';
  public static readonly PERMALINK_CITYWIDE_DELIVERY = 'nationwide-delivery';
  public static readonly PERMALINK_CREDIT_CARD = 'credit-card-payment-security';
  public static readonly PERMALINK_ABOUT_ONLINE = 'about-online';

  constructor() {
    super(Page.CLASS_NAME);
    this.enabled = true;
  }

  public get title(): string {
    return this.get(Page.TITLE_KEY);
  }

  public set title(title: string) {
    this.set(Page.TITLE_KEY, title);
  }

  public get metaTags(): MetaTags {
    return this.get(Page.METATAGS_KEY);
  }

  public set metaTags(metaTags: MetaTags) {
    this.set(Page.METATAGS_KEY, metaTags);
  }

  public get subtitle(): string {
    return this.get(Page.SUBTITLE_KEY);
  }

  public set subtitle(subtitle: string) {
    this.set(Page.SUBTITLE_KEY, subtitle);
  }

  public get image(): FileRef {
    return this.get(Page.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(Page.IMAGE_KEY, image);
  }

  public get imageUrl(): string {
    return this.image && this.image.url ? this.image.url : ''
  }

  public get content(): string {
    return this.get(Page.CONTENT_KEY);
  }

  public set content(content: string) {
    this.set(Page.CONTENT_KEY, content);
  }

  public entries() {
    return _.compact([this, this.image]);
  }

  public get permalink(): string {
    return this.get(Page.PERMALINK_KEY);
  }

  public set permalink(permalink: string) {
    this.set(Page.PERMALINK_KEY, permalink);
  }

  public get sortOrder(): number {
    return this.get(Page.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(Page.SORT_ORDER_KEY, sortOrder);
  }

  public get enabled(): boolean {
    return this.get(Page.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(Page.ENABLED_KEY, enabled);
  }
}

Parse.Object.registerSubclass(Page.CLASS_NAME, Page);
