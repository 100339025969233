import * as _ from 'lodash';

export class ProductTab {
  public key: string;
  public title: string;
  public index: number;

  public static toJson(tabs: ProductTab[]) {
    return tabs.reduce((result, tab) => {
      result[tab.key] = {
        title: tab.title,
        index: tab.index
      }

      return result;
    }, {});
  }

  public static toArray(json): ProductTab[] {
    return _.keys(json).reduce((result, k) => {
      const tab: ProductTab = {
        key: k,
        title: json[k].title,
        index: json[k].index
      } 
      
      result.push(tab)
      
      return result;
    }, []);
  }
}
