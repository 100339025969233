import { ParseQuery } from './parse-query';

import { ProductVariant } from './../data';

export class ProductVariantQuery extends ParseQuery<ProductVariant> {
  constructor() {
    super(ProductVariant);
  }

  public enabled(enabled: boolean = true) {
    this.equalTo(ProductVariant.ENABLED_KEY, enabled);
    return this;
  }

  public priceRange(low: number, high: number) {
    this.greaterThanOrEqualTo(ProductVariant.PRICE_KEY, low);
    this.lessThanOrEqualTo(ProductVariant.PRICE_KEY, high);
    return this;
  }
}