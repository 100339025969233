//import * as Parse from 'parse';

import { ParseObject } from './parse-object';
import { Product } from './product';
import { User } from 'parse';

export class CartProduct extends ParseObject {
  public static readonly kClassName = 'CartProduct';
  public static readonly kUserKey = 'user';
  public static readonly kProductKey = 'product';

  constructor() {
    super(CartProduct.kClassName);
  }

  public get user(): User {
    return this.get(CartProduct.kUserKey);
  }

  public set user(user: User) {
    this.set(CartProduct.kUserKey, user);
  }

  public get product(): Product {
    return this.get(CartProduct.kProductKey);
  }

  public set product(product: Product) {
    this.set(CartProduct.kProductKey, product);
  }
}

Parse.Object.registerSubclass(CartProduct.kClassName, CartProduct);
