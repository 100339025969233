import { ParseObservable } from '@shared/services';
import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { Banner, BannerItem, BannerOption } from './../data';
import { BannerQuery } from './../queries';

@Injectable()
export class BannerService {
  private _homeSlider: Observable<Banner>;
  private _homeLeftPromotionOne: Observable<Banner>;
  private _homeLeftPromotionTwo: Observable<Banner>;
  private _homeLeftPromotionThree: Observable<Banner>;
  private _homeRightPromotionOne: Observable<Banner>;

  constructor() {

  }

  public homeSlider() {
    if (!this._homeSlider) {
      this._homeSlider = this.banner(BannerOption.CODE_HOME_SLIDER);
    }
    return this._homeSlider;
  }

  public homeLeftPromotionOne() {
    if (!this._homeLeftPromotionOne) {
      this._homeLeftPromotionOne = this.banner(BannerOption.CODE_HOME_LEFT_PROMOTION_ONE);
    }
    return this._homeLeftPromotionOne;
  }

  public homeLeftPromotionTwo() {
    if (!this._homeLeftPromotionTwo) {
      this._homeLeftPromotionTwo = this.banner(BannerOption.CODE_HOME_LEFT_PROMOTION_TWO);
    }
    return this._homeLeftPromotionTwo;
  }

  public homeLeftPromotionThree() {
    if (!this._homeLeftPromotionThree) {
      this._homeLeftPromotionThree = this.banner(BannerOption.CODE_HOME_LEFT_PROMOTION_THREE);
    }
    return this._homeLeftPromotionThree;
  }

  public homeRightPromotionOne() {
    if (!this._homeRightPromotionOne) {
      this._homeRightPromotionOne = this.banner(BannerOption.CODE_HOME_RIGHT_PROMOTION_ONE);
    }
    return this._homeRightPromotionOne;
  }

  private banner(code: string) {
    let query = new BannerQuery()
      .equalTo(Banner.CODE_KEY, code)
      .include(Banner.ITEMS_KEY)
      .include([Banner.ITEMS_KEY, BannerItem.IMAGE_KEY].join('.'));

    return query.rx().first().publishReplay(1).refCount();
  }
}
