import { OrderStatus } from '@shared/data';
import { ParseObject } from './parse-object';

export class EmailVerification extends ParseObject {
  public static readonly CLASS_NAME = 'EmailVerification';
  public static readonly EMAIL_KEY = 'email';
  public static readonly VERIFY_CODE_KEY = 'verifyCode';

  public static readonly VERIFY_CODE_EXPIRES_AT_KEY = 'verifyCodeExpiresAt';
  public static readonly RESET_AT_KEY = 'resetAt';
  public static readonly BLOCK_UNTIL_KEY = 'blockUntil';
  public static readonly ATTEMPTS_KEY = 'attempts';

  constructor() {
    super(EmailVerification.CLASS_NAME);
  }

  public get email(): string {
    return this.get(EmailVerification.EMAIL_KEY);
  }

  public set email(email: string) {
    this.set(EmailVerification.EMAIL_KEY, email);
  }

  public get verifyCode(): number {
    return this.get(EmailVerification.VERIFY_CODE_KEY);
  }

  public set verifyCode(verifyCode: number) {
    this.set(EmailVerification.VERIFY_CODE_KEY, verifyCode);
  }

  public get verifyCodeExpiresAt(): Date {
    return this.get(EmailVerification.VERIFY_CODE_EXPIRES_AT_KEY);
  }

  public set verifyCodeExpiresAt(verifyCodeExpiresAt: Date) {
    this.set(EmailVerification.VERIFY_CODE_EXPIRES_AT_KEY, verifyCodeExpiresAt);
  }

  public get resetAt(): Date {
    return this.get(EmailVerification.RESET_AT_KEY);
  }

  public set resetAt(resetAt: Date) {
    this.set(EmailVerification.RESET_AT_KEY, resetAt);
  }

  public get blockUntil(): Date {
    return this.get(EmailVerification.BLOCK_UNTIL_KEY);
  }

  public set blockUntil(blockUntil: Date) {
    this.set(EmailVerification.BLOCK_UNTIL_KEY, blockUntil);
  }
}

Parse.Object.registerSubclass(EmailVerification.CLASS_NAME, EmailVerification);
