import { Injectable } from '@angular/core';

import { Observable, Observer, Subscriber } from 'rxjs';

import { LocalStorageService } from './local-storage-service';

const MAX_HISTORY = 10;

@Injectable()
export class SearchHistoryService {
  constructor(private localStorageService: LocalStorageService) {

  }

  public add(text: string) {
    let history = this.localStorageService.searchHistory;
    let index = history.indexOf(text);

    if (index != -1) {
      history.splice(index, 1);
    }

    if (history.length >= MAX_HISTORY) {
      history.splice(history.length -1 , 1);
    }

    history.unshift(text);

    this.localStorageService.searchHistory = history;
  }

  public remove(text: string) {
    let history = this.localStorageService.searchHistory;
    let index = history.indexOf(text);

    if (index != -1) {
      history.splice(index, 1);
    }

    this.localStorageService.searchHistory = history;
  }

  public get() {
    return this.localStorageService.searchHistory;
  }
}
