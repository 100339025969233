import { Routes } from '@angular/router';

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: './+web#WebModule'
  },
  {
    path: 'admin',
    loadChildren: './+admin#AdminModule'
  }
];
