import { IAddress } from './address';

export interface CheckoutProductDto {
  id: string;
  name: string;
}

export interface CheckoutVariantDto {
  id: string;
}

export interface CheckoutItemDto {
  variant: CheckoutVariantDto;
  product: CheckoutProductDto;
  quantity: number;
  inputOptions: any[];
}

export class CheckoutDto {
  items: CheckoutItemDto[];
  shipTo: IAddress;
  shippingPrice: number;
  amount: number;
  couponCode?: string;
}