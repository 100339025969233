import { CouponStatus } from '@shared/data';

import { ParseQuery } from './parse-query';
import { UserQuery } from './user-query';

export class CouponStatusQuery extends ParseQuery<CouponStatus> {
  constructor() {
    super(CouponStatus);
  }

  public code(code: string) {
    this.equalTo(CouponStatus.CODE_KEY, code);
    return this;
  }
}