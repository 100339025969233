import { CityQuery } from './../queries/city-query';
import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { City } from '@shared/data';

@Injectable()
export class CityService {
  private _list: Observable<City[]>

  constructor() {

  }

  public list() {
    if (!this._list) {
      this._list = new CityQuery()
        .include(City.DISTRICTS_KEY)
        .addAscending(City.SORT_ORDER_KEY)
        .addAscending(City.NAME_KEY)
        .rx()
        .find()
        .publishReplay(1)
        .refCount();
    }
    return this._list;
  }
}
