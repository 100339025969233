import { ParseObservable } from './parse-observable';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Collection } from './../data';
import { CollectionQuery } from './../queries';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class CollectionService {
  private _mostPopular: Observable<Collection>;
  private _newArrivals: Observable<Collection>;

  constructor() {

  }

  public mostPopular() {
    if (!this._mostPopular) {
      this._mostPopular = this.code(Collection.CODE_MOST_POPULAR).pipe(publishReplay(1)).pipe(refCount());
    }
    return this._mostPopular;
  }

  public newArrivals() {
    if (!this._newArrivals) {
      this._newArrivals = this.code(Collection.CODE_NEW_ARRIVALS).pipe(publishReplay(1)).pipe(refCount());
    }
    return this._newArrivals;
  }

  public code(code: string) {
    return new CollectionQuery().equalTo(Collection.CODE_KEY, code).rx().first();
  }
}
