import { Delivery } from './delivery';
import { ParseObject } from './parse-object';

import * as _ from 'lodash';

import { IAddress } from './address';
import { User } from './user';
import { OrderItem } from './order-item';
import { OrderStatus } from './order-status';
import { PaymentStatus } from './payment-status';
import { Refund } from './refund';
import { PaymentMethod } from './payment-method';
import { Coupon } from './coupon';

export class Order extends ParseObject {
  public static readonly CLASS_NAME = 'Order';
  public static readonly USER_KEY = 'user';
  public static readonly STATUS_KEY = 'status';
  public static readonly PAYMENT_STATUS_KEY = 'paymentStatus';
  public static readonly ORDER_NUMBER_KEY = 'orderNumber';
  public static readonly ITEMS_KEY = 'items';
  public static readonly TOTAL_PRICE_KEY = 'totalPrice';
  public static readonly SHIP_TO_KEY = 'shipTo';
  public static readonly SHIPPING_PRICE_KEY = 'shippingPrice';
  public static readonly DELIVERY_KEY = 'delivery';
  public static readonly MAX_ESTIMATED_DELIVERY_DATE_KEY = 'maxEstimatedDeliveryDate';
  public static readonly MIN_ESTIMATED_DELIVERY_DATE_KEY = 'minEstimatedDeliveryDate';
  public static readonly LOGS_KEY = 'logs';
  public static readonly REFUND_KEY = 'refund';
  public static readonly PAYMENT_METHOD_KEY = 'paymentMethod';
  public static readonly AMOUNT_KEY = 'amount';
  public static readonly COUPON_DISCOUNT_KEY = 'couponDiscount';
  public static readonly COUPON_KEY = 'coupon';

  constructor(id?: string) {
    super(Order.CLASS_NAME);
    this.id = id;
  }

  public get user(): User {
    return this.get(Order.USER_KEY);
  }

  public set user(user: User) {
    this.set(Order.USER_KEY, user);
  }

  public get status(): OrderStatus {
    return this.get(Order.STATUS_KEY);
  }

  public set status(status: OrderStatus) {
    this.set(Order.STATUS_KEY, status);
  }

  public get paymentStatus(): PaymentStatus {
    return this.get(Order.PAYMENT_STATUS_KEY);
  }

  public set paymentStatus(paymentStatus: PaymentStatus) {
    this.set(Order.PAYMENT_STATUS_KEY, paymentStatus);
  }

  public get orderNumber(): string {
    return this.get(Order.ORDER_NUMBER_KEY);
  }

  public set orderNumber(orderNumber: string) {
    this.set(Order.ORDER_NUMBER_KEY, orderNumber);
  }

  public get items(): OrderItem[] {
    return this.get(Order.ITEMS_KEY);
  }

  public set items(items: OrderItem[]) {
    this.set(Order.ITEMS_KEY, items);
  }

  public get shipTo(): IAddress  {
    return this.get(Order.SHIP_TO_KEY);
  }

  public set shipTo(address: IAddress) {
    this.set(Order.SHIP_TO_KEY, address);
  }

  public get shippingPrice(): number {
    return this.get(Order.SHIPPING_PRICE_KEY);
  }

  public set shippingPrice(shippingPrice: number) {
    this.set(Order.SHIPPING_PRICE_KEY, shippingPrice);
  }

  public get itemsTotal(): number {
    return _.sumBy(this.items, o => o.totalPrice);
  }

  public get total(): number {
    return this.itemsTotal + this.shippingPrice - (this.couponDiscount || 0);
  }

  public get delivery(): Delivery  {
    return this.get(Order.DELIVERY_KEY);
  }

  public set delivery(delivery: Delivery) {
    this.set(Order.DELIVERY_KEY, delivery);
  }

  public get maxEstimatedDeliveryDate(): Date  {
    return this.get(Order.MAX_ESTIMATED_DELIVERY_DATE_KEY);
  }

  public set maxEstimatedDeliveryDate(date: Date) {
    this.set(Order.MAX_ESTIMATED_DELIVERY_DATE_KEY, date);
  }

  public get minEstimatedDeliveryDate(): Date  {
    return this.get(Order.MIN_ESTIMATED_DELIVERY_DATE_KEY);
  }

  public set minEstimatedDeliveryDate(date: Date) {
    this.set(Order.MIN_ESTIMATED_DELIVERY_DATE_KEY, date);
  }

  public get logs(): any {
    return this.get(Order.LOGS_KEY);
  }

  public set logs(logs: any) {
    this.set(Order.LOGS_KEY, logs);
  }

  public get refund(): Refund {
    return this.get(Order.REFUND_KEY);
  }

  public set refund(refund: Refund) {
    this.set(Order.REFUND_KEY, refund);
  }

  public get paymentMethod(): PaymentMethod {
    return this.get(Order.PAYMENT_METHOD_KEY);
  }

  public set paymentMethod(paymentMethod: PaymentMethod) {
    this.set(Order.PAYMENT_METHOD_KEY, paymentMethod);
  }

  public get amount(): number {
    return this.get(Order.AMOUNT_KEY);
  }

  public set amount(amount: number) {
    this.set(Order.AMOUNT_KEY, amount);
  }

  public get couponDiscount(): number {
    return this.get(Order.COUPON_DISCOUNT_KEY);
  }

  public set couponDiscount(couponDiscount: number) {
    this.set(Order.COUPON_DISCOUNT_KEY, couponDiscount);
  }

  public get coupon(): Coupon {
    return this.get(Order.COUPON_KEY);
  }

  public set coupon(coupon: Coupon) {
    this.set(Order.COUPON_KEY, coupon);
  }

  public revert(): void {
    super.revert();

    if (this.user) {
      this.user.revert();
    }

    if (this.status) {
      this.status.revert();
    }
  }
}

Parse.Object.registerSubclass(Order.CLASS_NAME, Order);
