import { Notification } from './../data/notification';
import { ParseQuery } from './parse-query';

export class NotificationQuery extends ParseQuery<Notification> {

  constructor() {
    super(Notification);
  }

  public read(read: boolean) {
    this.equalTo(Notification.READ_KEY, read);
    return this;
  }

  public sortByLastCreatedDate() {
    this.descending(Notification.CREATE_AT_KEY);
  }
}