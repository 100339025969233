import { ParseObject } from './parse-object';

export abstract class Cascade extends ParseObject {
  public prevEntries: ParseObject[];

  public abstract entries(): ParseObject[];

  _finishFetch(serverData: any) {
    super['_finishFetch'](serverData);
    this.prevEntries = this.entries();
  }

  _handleSaveResponse(response: any, status: number) {
    super['_handleSaveResponse'](response, status);
    this.prevEntries = this.entries();
  }
}