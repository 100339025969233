import { ParseQuery } from './parse-query';

import { CouponGroup } from './../data/coupon-group';

import './../extensions/reg-exp+escape';

export class CouponGroupQuery extends ParseQuery<CouponGroup> {
  constructor() {
    super(CouponGroup);
  }
}
