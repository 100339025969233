import { ParseObject } from './parse-object';


export class BusinessCategory extends ParseObject {
  public static readonly CLASS_NAME = 'BusinessCategory';
  public static readonly TITLE_KEY = 'title';
  public static readonly SORT_ORDER_KEY = 'sortOrder';

  constructor() {
    super(BusinessCategory.CLASS_NAME);
  }

  public get title(): string {
    return this.get(BusinessCategory.TITLE_KEY);
  }

  public set title(title: string) {
    this.set(BusinessCategory.TITLE_KEY, title);
  }

  public get sortOrder(): number {
    return this.get(BusinessCategory.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(BusinessCategory.SORT_ORDER_KEY, sortOrder);
  }
}

Parse.Object.registerSubclass(BusinessCategory.CLASS_NAME, BusinessCategory);
