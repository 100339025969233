import { ParseQuery } from './parse-query';

import './../extensions/reg-exp+escape';

import { ShippingZone, City } from './../data';
import { CityQuery } from '@shared/queries';

export class ShippingZoneQuery extends ParseQuery<ShippingZone> {
  constructor() {
    super(ShippingZone);
  }

  public includeAll() {
    this.include(ShippingZone.CITY_KEY);
    return this;
  }

  public cityName(name: string) {
    let cityQuery = new CityQuery().equalTo('name', name);
    this.matchesQuery('city', cityQuery);
    return this;
  }
}