import { Relation } from 'parse';

import { User, CouponStatus } from '@shared/data';

import { ParseObject } from './parse-object';
import { CouponGroup } from './coupon-group';
import { Order } from './order';

export class Coupon extends ParseObject {
  public static readonly CLASS_NAME = 'Coupon';
  public static readonly CODE_KEY = 'code';
  public static readonly NAME_KEY = 'name';
  public static readonly TYPE_KEY = 'type';
  public static readonly DISCOUNT_KEY = 'discount';
  public static readonly GROUP_KEY = 'group';
  public static readonly MIN_PURCHASE_KEY = 'minPurchase';
  public static readonly START_DATE_KEY = 'startDate';
  public static readonly END_DATE_KEY = 'endDate';
  public static readonly STATUS_KEY = 'status';
  public static readonly DISTRIBUTOR_KEY = 'distributor';
  public static readonly TEAM_LEADER_KEY = 'teamLeader';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly TOTAL_DISCOUNT_KEY = 'totalDiscount';
  public static readonly TOTAL_PURCHASE_KEY = 'totalPurchase';
  public static readonly ORDER_NUMBER_KEY = 'orderNumber';
  public static readonly SHIPPING_PRICE_KEY = 'shippingPrice';
  public static readonly MAX_USES_KEY = 'maxUses';
  public static readonly CURRENT_USES_KEY = 'currentUses';
  public static readonly UNLIMITED_KEY = 'unlimited';
  public static readonly HISTORY_KEY = 'history';
  public static readonly ORDER_KEY = 'order';
  public static readonly COMMISSION_RATE_KEY = 'commissionRate';
  public static readonly NOTE_KEY = 'note';

  constructor() {
    super(Coupon.CLASS_NAME);
  }

  public get note(): string {
    return this.get(Coupon.NOTE_KEY);
  }

  public set note(note: string) {
    this.set(Coupon.NOTE_KEY, note);
  }

  public get group(): CouponGroup {
    return this.get(Coupon.GROUP_KEY);
  }

  public set group(group: CouponGroup) {
    this.set(Coupon.GROUP_KEY, group);
  }

  public get type(): string {
    return this.get(Coupon.TYPE_KEY);
  }

  public set type(type: string) {
    this.set(Coupon.TYPE_KEY, type);
  }

  public get name(): string {
    return this.get(Coupon.NAME_KEY);
  }

  public set name(name: string) {
    this.set(Coupon.NAME_KEY, name);
  }

  public get code(): string {
    return this.get(Coupon.CODE_KEY);
  }

  public set code(code: string) {
    this.set(Coupon.CODE_KEY, code);
  }

  public get discount(): number {
    return this.get(Coupon.DISCOUNT_KEY);
  }

  public set discount(discount: number) {
    this.set(Coupon.DISCOUNT_KEY, discount);
  }

  public get minPurchase(): number {
    return this.get(Coupon.MIN_PURCHASE_KEY);
  }

  public set minPurchase(minPurchase: number) {
    this.set(Coupon.MIN_PURCHASE_KEY, minPurchase);
  }

  public get startDate(): Date {
    return this.get(Coupon.START_DATE_KEY);
  }

  public set startDate(startDate: Date) {
    this.set(Coupon.START_DATE_KEY, startDate);
  }

  public get endDate(): Date {
    return this.get(Coupon.END_DATE_KEY);
  }

  public set endDate(endDate: Date) {
    this.set(Coupon.END_DATE_KEY, endDate);
  }

  public get status(): CouponStatus {
    return this.get(Coupon.STATUS_KEY);
  }

  public set status(status: CouponStatus) {
    this.set(Coupon.STATUS_KEY, status);
  }

  public get distributor(): User {
    return this.get(Coupon.DISTRIBUTOR_KEY);
  }

  public set distributor(distributor: User) {
    this.set(Coupon.DISTRIBUTOR_KEY, distributor);
  }

  public get teamLeader(): User {
    return this.get(Coupon.TEAM_LEADER_KEY);
  }

  public set teamLeader(teamLeader: User) {
    this.set(Coupon.TEAM_LEADER_KEY, teamLeader);
  }

  public get sortOrder(): number {
    return this.get(Coupon.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(Coupon.SORT_ORDER_KEY, sortOrder);
  }

  public get totalDiscount(): number {
    return this.get(Coupon.TOTAL_DISCOUNT_KEY);
  }

  public set totalDiscount(totalDiscount: number) {
    this.set(Coupon.TOTAL_DISCOUNT_KEY, totalDiscount);
  }

  public get totalPurchase(): number {
    return this.get(Coupon.TOTAL_PURCHASE_KEY);
  }

  public set totalPurchase(totalPurchase: number) {
    this.set(Coupon.TOTAL_PURCHASE_KEY, totalPurchase);
  }

  public get shippingPrice(): number {
    return this.get(Coupon.SHIPPING_PRICE_KEY);
  }

  public set shippingPrice(shippingPrice: number) {
    this.set(Coupon.SHIPPING_PRICE_KEY, shippingPrice);
  }

  public get orderNumber(): string {
    return this.get(Coupon.ORDER_NUMBER_KEY);
  }

  public set orderNumber(orderNumber: string) {
    this.set(Coupon.ORDER_NUMBER_KEY, orderNumber);
  }

  public get maxUses(): number {
    return this.get(Coupon.MAX_USES_KEY);
  }

  public set maxUses(maxUses: number) {
    this.set(Coupon.MAX_USES_KEY, maxUses);
  }

  public get currentUses(): number {
    return this.get(Coupon.CURRENT_USES_KEY);
  }

  public set currentUses(currentUses: number) {
    this.set(Coupon.CURRENT_USES_KEY, currentUses);
  }

  public get unlimited(): boolean {
    return this.get(Coupon.UNLIMITED_KEY);
  }

  public set unlimited(unlimited: boolean) {
    this.set(Coupon.UNLIMITED_KEY, unlimited);
  }

  public get order(): Order {
    return this.get(Coupon.ORDER_KEY);
  }

  public set order(order: Order) {
    this.set(Coupon.ORDER_KEY, order);
  }

  public get commissionRate(): number {
    return this.get(Coupon.COMMISSION_RATE_KEY);
  }

  public set commissionRate(commissionRate: number) {
    this.set(Coupon.COMMISSION_RATE_KEY, commissionRate);
  }

  public get history(): Relation {
    return this.relation(Coupon.HISTORY_KEY)
  }
}

Parse.Object.registerSubclass(Coupon.CLASS_NAME, Coupon);
