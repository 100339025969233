import { User } from './user';
import { ParseObject } from './parse-object';
import { Serializable } from './serializable';

import * as _ from 'lodash';

import { Product } from './product';
import { ProductVariant } from './product-variant';
import { ProductOption } from './product-option';
import { ProductVariantSnapshot } from './product-variant-snapshot';
import { ProductSnapshot } from './product-snapshot';

export class EnquiryItem extends ParseObject implements Serializable<EnquiryItem> {
  public static readonly CLASS_NAME = 'EnquiryItem';
  public static readonly QUANTITY_KEY = 'quantity';
  public static readonly PRODUCT_KEY = 'product';
  public static readonly VARIANT_KEY = 'variant';
  public static readonly PRODUCT_SNAPSHOT_KEY = 'productSnapshot';
  public static readonly VARIANT_SNAPSHOT_KEY = 'variantSnapshot';
  public static readonly USER_KEY = 'user';
  public static readonly INPUT_OPTIONS_KEY = 'inputOptions';

  constructor() {
    super(EnquiryItem.CLASS_NAME);
  }

  // ===============================================================================================
  // Override Methods
  // ===============================================================================================

  public serialize() {
    return {
      quantity: this.quantity,
      product: {
        id: this.product.id
      },
      productSnapshot: this.productSnapshot,
      variantSnapshot: this.variantSnapshot,
      variant: {
        id: this.variant.id
      },
      inputOptions: this.inputOptions
    }
  }

  public deserialize(json: any) {
    let o = new EnquiryItem();
    let variant = new ProductVariant();
    let product = Product.create()

    product.id = json['product'].id;
    variant.id = json['variant'].id;

    o.variant = variant;
    o.product = product;
    o.quantity = json['quantity'];
    o.productSnapshot = json['productSnapshot'];
    o.variantSnapshot = json['variantSnapshot'];
    o.inputOptions = json['inputOptions']

    return o;
  }

  // ===============================================================================================
  // Accessors
  // ===============================================================================================

  public get user() {
    return this.get(EnquiryItem.USER_KEY);
  }

  public set user(user: User) {
    this.set(EnquiryItem.USER_KEY, user);
  }

  public get quantity() {
    return this.get(EnquiryItem.QUANTITY_KEY);
  }

  public set quantity(quantity: number) {
    this.set(EnquiryItem.QUANTITY_KEY, quantity);
  }

  public get product() {
    return this.get(EnquiryItem.PRODUCT_KEY);
  }

  public set product(product: Product) {
    this.set(EnquiryItem.PRODUCT_KEY, product);
  }

  public get variant() {
    return this.get(EnquiryItem.VARIANT_KEY);
  }

  public set variant(variant: ProductVariant) {
    this.set(EnquiryItem.VARIANT_KEY, variant);
  }

  public get productSnapshot() {
    return this.get(EnquiryItem.PRODUCT_SNAPSHOT_KEY);
  }

  public set productSnapshot(productSnapshot: ProductSnapshot) {
    this.set(EnquiryItem.PRODUCT_SNAPSHOT_KEY, productSnapshot);
  }

  public get variantSnapshot() {
    return this.get(EnquiryItem.VARIANT_SNAPSHOT_KEY);
  }

  public set variantSnapshot(variantSnapshot: ProductVariantSnapshot) {
    this.set(EnquiryItem.VARIANT_SNAPSHOT_KEY, variantSnapshot);
  }

  public get inputOptions() {
    return this.get(EnquiryItem.INPUT_OPTIONS_KEY);
  }

  public set inputOptions(inputOptions: any) {
    this.set(EnquiryItem.INPUT_OPTIONS_KEY, inputOptions);
  }

  public get price() {
    return this.available ? this.variant.price : this.variantSnapshot ? this.variantSnapshot.price : undefined;
  }

  public get totalPrice() {
    return this.price * this.quantity;
  }

  public get available() {
    return this.product && this.product.enabled && this.product.available && this.variant && this.variant.enabled && this.variant.available;
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  public same(item: EnquiryItem) {
    return item.product.id == this.product.id && item.variant.id == this.variant.id;
  }

  public values() {
    let properties = this.available ? this.variant.properties : this.variantSnapshot ? this.variantSnapshot.properties : {};
    let colorTitle: string = ''

    this.product.tabs.forEach(o => {
      if(o.key == 'color') {
        colorTitle = o.title
      }
    });

    return _.keys(properties).map(k => {
      if(k == 'Color')
      return {
        key: colorTitle,
        value: properties[k]
      };

      else return {
        key: k,
        value: properties[k]
      };

    });
  }

  public image() {
    let color = this.variant.color;
    let variantImage = this.product ? this.product.firstImage(ProductOption.COLOR, color) : null;
    let defaultVariantImage = this.product ? this.product.defaultVariant.firstImage : null;
    return variantImage || defaultVariantImage;
  }
}

Parse.Object.registerSubclass(EnquiryItem.CLASS_NAME, EnquiryItem);
