import { Cascade } from './cascade';
import { ParseObject } from './parse-object';

import * as _ from 'lodash';

import { Size } from './size';
import { ProductOption } from './product-option';
import { FileRef } from './file-ref';

export class ProductVariant extends Cascade {
  public static readonly CLASS_NAME = 'ProductVariant';
  public static readonly VALUE_KEY = 'value';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly ORIGINAL_PRICE_KEY = 'originalPrice';
  public static readonly PRICE_KEY = 'price';
  public static readonly QUANTITY_KEY = 'quantity';
  public static readonly PROPERTIES_KEY = 'properties';
  public static readonly IMAGES_KEY = 'images';
  public static readonly SKU_KEY = 'sku';
  public static readonly LOW_STOCK_ALERT_QUANTITY_KEY = 'lowStockAlertQuantity';

  public static readonly DEFAULT_LOW_STOCK_ALERT_QUANTITY: number = 10;
  public static readonly IMAGE_SIZE: Size = { w: 350, h: 230 };

  private _adjustmentId: string;

  constructor(properties?: Object) {
    super(ProductVariant.CLASS_NAME);
    this.properties = properties;
    this.enabled = true;
    this.lowStockAlertQuantity = ProductVariant.DEFAULT_LOW_STOCK_ALERT_QUANTITY;
    this.images = [];
    this.quantity = 0;
  }

  public static variants(opts: ProductOption[]): ProductVariant[] {
    let variants: ProductVariant[] = [];
    let options: ProductOption[] = opts.filter(o => o.values.length);

    let cartesian = (options: ProductOption[], n, c) => {
      if(n == options.length) {
        return variants.push(new ProductVariant(_.clone(c)));
      }

      let option = options[n];

      option.values.forEach(v => {
        c[option.name] = v;
        cartesian(options, n + 1, c);
      });
    }

    if (options.length) {
      cartesian(options, 0, {});
    }

    return variants;
  }

  public get imageSize(): Size {
    return ProductVariant.IMAGE_SIZE;
  }

  public get enabled(): boolean {
    return this.get(ProductVariant.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(ProductVariant.ENABLED_KEY, enabled);
  }

  public get originalPrice(): number {
    return this.get(ProductVariant.ORIGINAL_PRICE_KEY);
  }

  public set originalPrice(originalPrice: number) {
    this.set(ProductVariant.ORIGINAL_PRICE_KEY, originalPrice);
  }

  public get price(): number {
    return this.get(ProductVariant.PRICE_KEY);
  }

  public set price(price: number) {
    this.set(ProductVariant.PRICE_KEY, price);
  }

  public get quantity(): number {
    return this.get(ProductVariant.QUANTITY_KEY);
  }

  public set quantity(quantity: number) {
    this.set(ProductVariant.QUANTITY_KEY, quantity);
  }

  public get images(): FileRef[] {
    return this.get(ProductVariant.IMAGES_KEY);
  }

  public set images(images: FileRef[]) {
    this.set(ProductVariant.IMAGES_KEY, images);
  }

  public get properties(): any {
    return this.get(ProductVariant.PROPERTIES_KEY);
  }

  public set properties(properties: any) {
    this.set(ProductVariant.PROPERTIES_KEY, properties);
  }

  public get sku(): string {
    return this.get(ProductVariant.SKU_KEY);
  }

  public set sku(sku: string) {
    this.set(ProductVariant.SKU_KEY, sku);
  }

  public get lowStockAlertQuantity(): number {
    return this.get(ProductVariant.LOW_STOCK_ALERT_QUANTITY_KEY);
  }

  public set lowStockAlertQuantity(lowStockAlertQuantity: number) {
    this.set(ProductVariant.LOW_STOCK_ALERT_QUANTITY_KEY, lowStockAlertQuantity);
  }

  public get firstImage() {
    return this.images && this.images.length ? this.images[0] : null;
  }

  public get color() {
    return this.properties ? this.properties[ProductOption.COLOR] : undefined;
  }

  public get isLowStock() {
    return this.quantity && this.lowStockAlertQuantity >= this.quantity;
  }

  public get adjustmentId() {
    if (!this._adjustmentId) {
      this._adjustmentId = Math.floor(Math.random() * 10000000) + '';
    }

    return this._adjustmentId;
  }
  
  public entries() {
    return _.concat<ParseObject>(this.images, this);
  }
}

Parse.Object.registerSubclass(ProductVariant.CLASS_NAME, ProductVariant);
