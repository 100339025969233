import { ParseQuery } from './parse-query';
import { UserQuery } from './user-query';
import { DeliveryStatus } from '@shared/data';

export class DeliveryStatusQuery extends ParseQuery<DeliveryStatus> {
  constructor() {
    super(DeliveryStatus);
  }

  public code(code: string) {
    this.equalTo(DeliveryStatus.CODE_KEY, code);
    return this;
  }
}