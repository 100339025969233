import { ParseObject } from './parse-object';

export class OrderStatus extends ParseObject {
  public static readonly CLASS_NAME = 'OrderStatus';
  public static readonly NAME_KEY = 'name';
  public static readonly CODE_KEY = 'code';
  public static readonly COLOR_KEY = 'color';

  public static readonly NONE_STATUS = 'none';
  public static readonly NEW_STATUS = 'new';
  public static readonly ASSIGNED_STATUS = 'assigned';
  public static readonly DELIVERING_STATUS = 'delivering';
  public static readonly COMPLETED_STATUS = 'completed';
  public static readonly COMPLETED_AND_VERIFIED_STATUS = 'completedAndVerified';
  public static readonly CANCELLED_STATUS = 'cancelled';
  public static readonly REFUNDED_STATUS = 'refunded';
  public static readonly REJECTED_STATUS = 'rejected';

  constructor() {
    super(OrderStatus.CLASS_NAME);
  }

  public get name(): string {
    return this.get(OrderStatus.NAME_KEY);
  }

  public set name(name: string) {
    this.set(OrderStatus.NAME_KEY, name);
  }

  public get code(): string {
    return this.get(OrderStatus.CODE_KEY);
  }

  public set code(code: string) {
    this.set(OrderStatus.CODE_KEY, code);
  }

  public get color(): string {
    return this.get(OrderStatus.COLOR_KEY);
  }
}

Parse.Object.registerSubclass(OrderStatus.CLASS_NAME, OrderStatus);
