import * as _ from 'lodash';

export class ParseObject extends Parse.Object {
  public static readonly OBJECT_ID_KEY = 'objectId';
  public static readonly UPDATE_AT_KEY = 'updatedAt';
  public static readonly CREATE_AT_KEY = 'createdAt';

  private _prevEntries: ParseObject[];

  constructor(className: string) {
    super(className);
    this._prevEntries = [];
  }

  // public get updatedAt(): Date {
  //   return this.get(ParseObject.UPDATE_AT_KEY);
  // }

  // public set updatedAt(date: Date) {
  //   this.set(ParseObject.UPDATE_AT_KEY, date);
  // }

  // public get createdAt(): Date {
  //   return this.get(ParseObject.CREATE_AT_KEY);
  // }

  // public set createdAt(date: Date) {
  //   this.set(ParseObject.CREATE_AT_KEY, date);
  // }

  public revert(): void {
    super['revert']();
  }

  public isNew(): boolean {
    return super['isNew']();
  }

  public dirty(attr?: string) {
    return super.dirty(attr);
  }

  public toFullJSON(seen?: any[]) {
    return super['_toFullJSON'](seen);
  }

  public get available() {
    return !!this.createdAt;
  }
}