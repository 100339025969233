import { ParseQuery } from './parse-query';

import { ShoppingCart, User } from './../data';

export class ShoppingCartQuery extends ParseQuery<ShoppingCart> {
  constructor() {
    super(ShoppingCart);
  }

  public current() {
    this.equalTo(ShoppingCart.USER_KEY, User.current());
    return this;
  }
}

