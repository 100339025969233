import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { Observable, of } from 'rxjs';
import { switchMap, tap ,first, flatMap, map, merge, publishReplay, refCount } from 'rxjs/operators';


import { LocalStorageService } from './local-storage-service';
import { UserService } from './user-service';
import { ProductQuery } from './../queries/product-query';
import { EnquiryQuery } from '@shared/queries';
import { Enquiry, EnquiryItem, User, Product, ProductVariant, SubmitEnquiryDto } from './../data';

@Injectable()
export class EnquiryService {
  private _enquiry: Observable<Enquiry>;

  constructor(private localStorageService: LocalStorageService, private userService: UserService) {
    
  }

  // ===============================================================================================
  // Accessors
  // ===============================================================================================

  public get enquiry() {
    if (!this._enquiry) {
      this._enquiry = this.userService.user.pipe(switchMap(
        user => {
          let localEnquiry = this.localStorageService.enquiry || new Enquiry();

          if (user) {
            let query = new EnquiryQuery()
              .current()
              .include(Enquiry.ITEMS_KEY)
              .include([Enquiry.ITEMS_KEY, EnquiryItem.PRODUCT_KEY].join('.'))
              .include([Enquiry.ITEMS_KEY, EnquiryItem.PRODUCT_KEY, Product.VARIANTS_KEY].join('.'))
              .include([Enquiry.ITEMS_KEY, EnquiryItem.PRODUCT_KEY, Product.VARIANTS_KEY, ProductVariant.IMAGES_KEY].join('.'))
              .include([Enquiry.ITEMS_KEY, EnquiryItem.PRODUCT_KEY, Product.DEFAULT_VARIANT_KEY].join('.'))
              .include([Enquiry.ITEMS_KEY, EnquiryItem.PRODUCT_KEY, Product.DEFAULT_VARIANT_KEY, ProductVariant.IMAGES_KEY].join('.'));

            return query.rx().first().pipe(switchMap(
              value => {
                if (value) {

                  if (localEnquiry && localEnquiry.items.length) {

                    localEnquiry.items.forEach(i => {
                      let exist = value.items.find(o => o.same(i));

                      if (exist) {
                        exist.quantity += i.quantity;
                      } else {
                        i.user = user;
                        value.add(Enquiry.ITEMS_KEY, i);
                      }
                    });

                    let result = value.rx().save().pipe(
                      merge(this.fetch(value))).pipe(
                      tap(() => {
                        this.localStorageService.enquiry = null;
                      }));


                    result.pipe(first()).subscribe(
                      v => {
                        console.log(v);
                      },
                      error => {
                        console.log(error);
                      }
                    )

                    return result;
                  }

                  return of(value);
                } else {
                  localEnquiry.user = user;

                  return this.fetch(localEnquiry).pipe(
                    merge(localEnquiry.rx().save())).pipe(
                    tap(() => {
                      this.localStorageService.enquiry = null;
                    }));
                }
              }
            ))
          } else {
            return this.fetch(localEnquiry)
          }
        }
      )).pipe(publishReplay(1)).pipe(refCount());
    }
    return this._enquiry;
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  public addItem(item: EnquiryItem) {
    return this.enquiry.pipe(flatMap(
      value => {
        let exist = value.items.find(o => {
          const matched = _.isEqual(o.inputOptions, item.inputOptions)

          return o.variant.id == item.variant.id && o.product.id == item.product.id && matched
        });

        if (exist) {
          exist.quantity += item.quantity;
        } else {
          item.user = User.current();
          value.add(Enquiry.ITEMS_KEY, item);
        }

        return this.save(value);
      }
    )).pipe(first());
  }

  public removeItem(item: EnquiryItem) {
    return this.enquiry.pipe(flatMap(
      value => {
        value.remove(Enquiry.ITEMS_KEY, item);
        item.destroy();
        return this.save(value);
      }
    )).pipe(first());
  }

  public removeMany(items: EnquiryItem[]) {
    return this.enquiry.pipe(flatMap(
      value => {
        _.forEach(items, (o => {
          value.remove(Enquiry.ITEMS_KEY, o)
        }))
        // value.remove(Enquiry.ITEMS_KEY, items);
        EnquiryItem.destroyAll(items);
        return this.save(value);
      }
    )).pipe(first());
  }

  public removeAll() {
    return this.enquiry.pipe(flatMap(
      value => {
        value.items = [];
        EnquiryItem.destroyAll(value.items);
        return this.save(value);
      }
    )).pipe(first());
  }

  public async submit(submitEnquiryDto: SubmitEnquiryDto) {
    return await Parse.Cloud.run('enquiry-submit', submitEnquiryDto)
  }

  // ===============================================================================================
  // Private Methods
  // ===============================================================================================

  private save(enquiry: Enquiry) {
    if (enquiry.user) {
      return enquiry.rx().save();
    }

    this.localStorageService.enquiry = enquiry;

    return of(enquiry);
  }

  private fetch(enquiry: Enquiry) {
    let productIds = enquiry.items.filter(o => !_.isNull(o.product)).map(o => o.product.id);
    
    return new ProductQuery()
      .includeVariants()
      .includeDefaultVariant()
      .containedIn(Product.OBJECT_ID_KEY, productIds)
      .rx()
      .find().pipe(map(o => enquiry));
  }
}
