import { ParseQuery } from './parse-query';

import { Enquiry, User, EnquiryItem, Product, ProductVariant } from './../data';

export class EnquiryQuery extends ParseQuery<Enquiry> {
  constructor() {
    super(Enquiry.CLASS_NAME);
  }

  public current() {
    this.equalTo(Enquiry.USER_KEY, User.current());
    return this;
  }

  public search(searchText: string) {
    let queries: Parse.Query[] = [];

    queries.push(new EnquiryQuery().searchKey(Enquiry.CUSTOMER_NAME_KEY, searchText));
    queries.push(new EnquiryQuery().searchKey(Enquiry.EMAIL_KEY, searchText));

    this.orQuery(queries);

    return this;
  }

  public submitted() {
    this.equalTo(Enquiry.SUBMITTED_KEY, true)
    return this
  }

  public includeItems() {
    this.include([Enquiry.ITEMS_KEY, EnquiryItem.PRODUCT_KEY, Product.VARIANTS_KEY, ProductVariant.IMAGES_KEY].join('.'))
      .include([Enquiry.ITEMS_KEY, EnquiryItem.PRODUCT_KEY, Product.DEFAULT_VARIANT_KEY, ProductVariant.IMAGES_KEY].join('.'));
    return this
  }
}
