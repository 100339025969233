import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { map, debounceTime, share, takeLast, publishReplay, refCount } from 'rxjs/operators';

import { ConfigurationQuery } from './../queries';
import { Configuration } from './../data';

import { first } from 'rxjs/operators'

@Injectable()
export class ConfigurationService {

  constructor() {

  }

  public common() {
    return new ConfigurationQuery()
    .common()
    .rx()
    .first().pipe(publishReplay(1)).pipe(refCount())
    
    
  }
}
