export const DEFAULT_HEADLINE_MENU = [
  // {
  //   title: 'MY CART',
  //   url: '/shopping-cart',
  //   icon: '/assets/img/shopping-cart/my_cart.png'
  // },
  {
    title: 'ENQUIRY LIST',
    url: '/enquiry',
    icon: '/assets/img/shopping-cart/enquiry.png'
  },
  // {
  //   title: 'BUY IT AGAIN',
  //   url: '/buy-it-again',
  //   icon: '/assets/img/shopping-cart/buy_again.png'
  // },
  {
    title: 'VIEWED ITEMS',
    url: '/viewed-item',
    icon: '/assets/img/shopping-cart/viewed_items.png'
  }
];

export const LOGOUT_HEADLINE_MENU = [
  // {
  //   title: 'MY CART',
  //   url: '/shopping-cart',
  //   icon: '/assets/img/shopping-cart/my_cart.png'
  // },
  {
    title: 'ENQUIRY LIST',
    url: '/enquiry',
    icon: '/assets/img/shopping-cart/enquiry.png'
  }
];