import { ParseObject } from './parse-object';
import { Order } from './order';

import * as _ from 'lodash'

export class CouponHistory extends ParseObject {
  public static readonly CLASS_NAME = 'CouponHistory';
  public static readonly TOTAL_PURCHASE_KEY = 'totalPurchase';
  public static readonly TOTAL_DISCOUNT_KEY = 'totalDiscount';
  public static readonly ORDER_NUMBER_KEY = 'orderNumber';
  public static readonly SHIPPING_PRICE_KEY = 'shippingPrice';
  public static readonly ORDER_KEY = 'order';
  public static readonly CUSTOMER_KEY = 'customer';
  public static readonly USE_DATE_KEY = 'useDate';
  public static readonly COMMISSION_KEY = 'commission';
  public static readonly COMMISSION_RATE_KEY = 'commissionRate';
  public static readonly ITEM_TOTAL_KEY = 'itemsTotal';

  constructor() {
    super(CouponHistory.CLASS_NAME)
  }

  public get orderNumber() {
    return this.get(CouponHistory.ORDER_NUMBER_KEY)
  }

  public set orderNumber(orderNumber) {
    this.set(CouponHistory.ORDER_NUMBER_KEY, orderNumber)
  }

  public get totalPurchase() {
    return this.get(CouponHistory.TOTAL_PURCHASE_KEY)
  }

  public set totalPurchase(totalPurchase) {
    this.set(CouponHistory.TOTAL_PURCHASE_KEY, totalPurchase)
  }

  public get itemsTotal() {
    return this.get(CouponHistory.ITEM_TOTAL_KEY)
  }

  public set itemsTotal(itemsTotal) {
    this.set(CouponHistory.ITEM_TOTAL_KEY, itemsTotal)
  }

  public get totalDiscount() {
    return this.get(CouponHistory.TOTAL_DISCOUNT_KEY)
  }

  public set totalDiscount(totalDiscount) {
    this.set(CouponHistory.TOTAL_DISCOUNT_KEY, totalDiscount)
  }

  public get shippingPrice() {
    return this.get(CouponHistory.SHIPPING_PRICE_KEY)
  }

  public set shippingPrice(shippingPrice) {
    this.set(CouponHistory.SHIPPING_PRICE_KEY, shippingPrice)
  }

  public get order(): Order {
    return this.get(CouponHistory.ORDER_KEY)
  }

  public set order(order: Order) {
    this.set(CouponHistory.ORDER_KEY, order)
  }

  public get customer() {
    return this.get(CouponHistory.CUSTOMER_KEY)
  }

  public set customer(customer) {
    this.set(CouponHistory.CUSTOMER_KEY, customer)
  }

  public get useDate() {
    return this.get(CouponHistory.USE_DATE_KEY)
  }

  public set useDate(useDate) {
    this.set(CouponHistory.USE_DATE_KEY, useDate)
  }

  public get commissionRate(): number {
    return this.get(CouponHistory.COMMISSION_RATE_KEY)
  }

  public set commissionRate(commissionRate: number) {
    this.set(CouponHistory.COMMISSION_RATE_KEY, commissionRate)
  }

  public get commission(): number {
    return _.round(this.itemsTotal * (this.commissionRate / 100), 2)
  }

  public get saleAmount(): number {
    return _.round(this.itemsTotal - this.totalDiscount - this.commission, 2)
  }
}

Parse.Object.registerSubclass(CouponHistory.CLASS_NAME, CouponHistory);
