//import * as Parse from 'parse';

import { ParseObject } from './parse-object';

export class Vendor extends ParseObject {
  public static readonly kClassName = 'Vendor';

  constructor() {
    super(Vendor.kClassName);
  }
}

Parse.Object.registerSubclass(Vendor.kClassName, Vendor);
