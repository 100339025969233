import { ParseQuery } from './parse-query';

import { FeatureProduct } from '../data/feature-product';
import { Category } from '@shared/data';

export class FeatureProductQuery extends ParseQuery<FeatureProduct> {
  constructor() {
    super(FeatureProduct);
  }

  public current(categoryId: string) {
    const category = new Category()
    category.id = categoryId
    this.equalTo(FeatureProduct.CATEGORY_KEY, category);
    return this;
  }
}