import * as _ from 'lodash';

import { ParseObject } from './parse-object';
import { Coupon } from './coupon';
import { User } from '@shared/data';

export class CouponGroup extends ParseObject {
  public static readonly CLASS_NAME = 'CouponGroup';
  public static readonly NAME_KEY = 'name';
  public static readonly PREFIX_KEY = 'prefix';
  public static readonly DESCRIPTION_KEY = 'desc';
  public static readonly TEAM_LEADER_KEY = 'teamLeader';

  constructor() {
    super(CouponGroup.CLASS_NAME);
  }

  public get name(): string {
    return this.get(CouponGroup.NAME_KEY);
  }

  public set name(name: string) {
    this.set(CouponGroup.NAME_KEY, name);
  }

  public get description(): string {
    return this.get(CouponGroup.DESCRIPTION_KEY);
  }

  public set description(description: string) {
    this.set(CouponGroup.DESCRIPTION_KEY, description);
  }

  public get prefix(): string {
    return this.get(CouponGroup.PREFIX_KEY);
  }

  public set prefix(prefix: string) {
    this.set(CouponGroup.PREFIX_KEY, prefix);
  }

  public get teamLeader(): User {
    return this.get(CouponGroup.TEAM_LEADER_KEY);
  }

  public set teamLeader(teamLeader: User) {
    this.set(CouponGroup.TEAM_LEADER_KEY, teamLeader);
  }
}

Parse.Object.registerSubclass(CouponGroup.CLASS_NAME, CouponGroup);
