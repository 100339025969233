import { ParseObject } from './parse-object';

export class City extends ParseObject {
  public static readonly CLASS_NAME = 'City';
  public static readonly NAME_KEY = 'name';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly DISTRICTS_KEY = 'districts';

  public static readonly NAME_PHNOM_PENH = 'Phnom Penh';

  constructor() {
    super(City.CLASS_NAME);
  }

  public get name(): string {
    return this.get(City.NAME_KEY);
  }

  public set name(contactName: string) {
    this.set(City.NAME_KEY, contactName);
  }

  public get sortOrder(): string {
    return this.get(City.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: string) {
    this.set(City.SORT_ORDER_KEY, sortOrder);
  }

  public get districts(): string[] {
    return this.get(City.DISTRICTS_KEY);
  }

  public set districts(districts: string[]) {
    this.set(City.DISTRICTS_KEY, districts);
  }
}

Parse.Object.registerSubclass(City.CLASS_NAME, City);
