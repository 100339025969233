import * as _ from 'lodash'

import { ParseObject } from './parse-object';

import { Product } from './product';
import { ProductVariant } from './product-variant';
import { User } from './user';

export class InventoryHistory extends ParseObject {
  public static readonly CLASS_NAME = 'InventoryHistory';
  public static readonly ADJUSTMENT_KEY = 'adjustment';
  public static readonly ADJUSTED_BY_KEY = 'adjustedBy';
  public static readonly EVENT_KEY = 'event';
  public static readonly QUANTITY_KEY = 'quantity';
  public static readonly VARIANT_KEY = 'variant';
  public static readonly PRODUCT_KEY = 'product';

  public static readonly MANUALLY_ADJUST = 'manuallyAdjust';
  
  constructor() {
    super(InventoryHistory.CLASS_NAME)
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  public static create(adjustment: number, adjustBy: User, event: string, product: Product, variant: ProductVariant, quanity: number) {
    const history = new InventoryHistory()

    history.adjustment = adjustment;
    history.adjustBy = adjustBy;
    history.event = event;
    history.variant = variant;
    history.product = product;
    history.quantity = quanity;

    return history
  }

  public static histories(adjustments, adjustBy: User, event: string, product: Product, variants: ProductVariant[]) {
    const histories = _.keys(adjustments)
      .filter(k => !!variants.find(o => o.adjustmentId == k))
      .map(k => {
        const variant = variants.find(o => o.adjustmentId == k)
        const adjustment = adjustments[k]

        return InventoryHistory.create(
          adjustment.value || 0,
          adjustBy,
          event,
          product,
          variant,
          variant.quantity || 0
        )
      })
      .filter(o => !!o.adjustment)

    return histories
  }

  // ===============================================================================================
  // Setters & Getter
  // ===============================================================================================

  public set adjustment(adjustment: number) {
    this.set(InventoryHistory.ADJUSTMENT_KEY, adjustment);
  }

  public get adjustment(): number {
    return this.get(InventoryHistory.ADJUSTMENT_KEY);
  }

  public set adjustBy(adjustBy: User) {
    this.set(InventoryHistory.ADJUSTED_BY_KEY, adjustBy);
  }

  public get adjustBy(): User {
    return this.get(InventoryHistory.ADJUSTED_BY_KEY);
  }

  public set event(event: string) {
    this.set(InventoryHistory.EVENT_KEY, event);
  }

  public get event(): string {
    return this.get(InventoryHistory.EVENT_KEY);
  }

  public set variant(variant: ProductVariant) {
    this.set(InventoryHistory.VARIANT_KEY, variant);
  }

  public get variant(): ProductVariant {
    return this.get(InventoryHistory.VARIANT_KEY);
  }

  public set product(product: Product) {
    this.set(InventoryHistory.PRODUCT_KEY, product);
  }

  public get product(): Product {
    return this.get(InventoryHistory.PRODUCT_KEY);
  }

  public set quantity(quantity: number) {
    this.set(InventoryHistory.QUANTITY_KEY, quantity);
  }

  public get quantity(): number {
    return this.get(InventoryHistory.QUANTITY_KEY);
  }
}

Parse.Object.registerSubclass(InventoryHistory.CLASS_NAME, InventoryHistory);
