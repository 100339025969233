import * as _ from 'lodash';

import { Observable } from 'rxjs';

import { AttachmentQuery } from '@shared/queries';
import { Injectable } from '@angular/core';

import { Attachment } from './../data';
import { AttachmentGroupService } from './attachment-group-service';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class AttachmentService {
  private _videoProducts: Observable<Attachment[]>;

  constructor(private attachmentGroupService: AttachmentGroupService) {

  }

  public videoProducts() {
    if (!this._videoProducts) {
      let query = new AttachmentQuery()
        .include(Attachment.IMAGE_KEY)
        .equalTo(Attachment.TYPE_KEY, Attachment.TYPE_VIDEO)
        .equalTo(Attachment.SHOW_AT_HOME_KEY, true)
        .descending(Attachment.VIEWS_KEY)
        .limit(3);
        
      this._videoProducts = query.rx().find().pipe(publishReplay(1)).pipe(refCount());
    }
    return this._videoProducts;
  }
}
