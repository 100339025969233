import { Injectable } from '@angular/core';

import { LocalStorageService as LocalStorage } from 'angular-2-local-storage';

import { ShoppingCart, Enquiry } from './../data';

const SHOPPING_CART_KEY = 'shopping-cart';
const ENQUIRY_KEY = 'enquiry';
const SEARCH_HISTORY_KEY = 'search-history';

@Injectable()
export class LocalStorageService {
  constructor(private localStorage: LocalStorage) {

  }

  public get shoppingCart() {
    let json = this.localStorage.get(SHOPPING_CART_KEY);
    return json ? new ShoppingCart().deserialize(json) : null;
  }

  public set shoppingCart(shoppingCart: ShoppingCart) {
    if (shoppingCart) {
      this.localStorage.set(SHOPPING_CART_KEY, shoppingCart.serialize());
    } else {
      this.localStorage.remove(SHOPPING_CART_KEY);
    }
  }

  public get enquiry() {
    let json = this.localStorage.get(ENQUIRY_KEY);
    return json ? new Enquiry().deserialize(json) : null;
  }

  public set enquiry(enquiry: Enquiry) {
    if (enquiry) {
      this.localStorage.set(ENQUIRY_KEY, enquiry.serialize());
    } else {
      this.localStorage.remove(ENQUIRY_KEY);
    }
  }

  public get searchHistory() {
    return this.localStorage.get(SEARCH_HISTORY_KEY) as string[] || [];
  }

  public set searchHistory(searchHistory: string[]) {
    this.localStorage.set(SEARCH_HISTORY_KEY, searchHistory);
  }
}
