export class BannerOption {
  public static readonly HOME_SLIDER = 'Home Slider';
  public static readonly HOME_LEFT_PROMOTION_ONE = 'Home Left Promotion One';
  public static readonly HOME_LEFT_PROMOTION_TWO = 'Home Left Promotion Two';
  public static readonly HOME_LEFT_PROMOTION_THREE = 'Home Left Promotion Three';
  public static readonly HOME_RIGHT_PROMOTION_ONE = 'Home Right Promotion One';

  public static readonly CODE_HOME_SLIDER = 'home_slider';
  public static readonly CODE_HOME_LEFT_PROMOTION_ONE = 'home_left_promotion_one';
  public static readonly CODE_HOME_LEFT_PROMOTION_TWO = 'home_left_promotion_two';
  public static readonly CODE_HOME_LEFT_PROMOTION_THREE = 'home_left_promotion_three';
  public static readonly CODE_HOME_RIGHT_PROMOTION_ONE = 'home_right_promotion_one';
  public static readonly CODE_MARKETING_PAGE = 'marketing_page';

  public readonly code: string;
  public readonly position: string;
  public readonly width: number;
  public readonly height: number;

  constructor(code: string, position: string, width: number, height: number) {
    this.code = code;
    this.position = position;
    this.width = width;
    this.height = height;
  }

  public static options() {
    return [
      new BannerOption(BannerOption.CODE_HOME_SLIDER, BannerOption.HOME_SLIDER, 880, 340),
      new BannerOption(BannerOption.CODE_HOME_LEFT_PROMOTION_ONE, BannerOption.HOME_LEFT_PROMOTION_ONE, 850, 110),
      new BannerOption(BannerOption.CODE_HOME_LEFT_PROMOTION_TWO, BannerOption.HOME_LEFT_PROMOTION_TWO, 850, 110),
      new BannerOption(BannerOption.CODE_HOME_LEFT_PROMOTION_THREE, BannerOption.HOME_LEFT_PROMOTION_THREE, 850, 110),
      new BannerOption(BannerOption.CODE_HOME_RIGHT_PROMOTION_ONE, BannerOption.HOME_RIGHT_PROMOTION_ONE, 270, 470),
    ];
  }
}