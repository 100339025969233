import { ParseObject } from './parse-object';

import { MarketingPageSection } from './marketing-page-section';

export class MarketingPage extends ParseObject {
  public static readonly CLASS_NAME = 'MarketingPage';
  public static readonly TITLE_KEY = 'title';
  public static readonly SECTIONS_KEY = 'sections';
  public static readonly PERMALINK_KEY = 'permalink';

  public static readonly MOBILE_APPS = 'mobile-apps';

  constructor() {
    super(MarketingPage.CLASS_NAME);
  }

  public static create() {
    const marketingPage = new MarketingPage()

    marketingPage.sections = []

    return marketingPage
  }

  public get title(): string {
    return this.get(MarketingPage.TITLE_KEY);
  }

  public set title(title: string) {
    this.set(MarketingPage.TITLE_KEY, title);
  }

  public get sections(): MarketingPageSection[] {
    return this.get(MarketingPage.SECTIONS_KEY);
  }

  public set sections(sections: MarketingPageSection[]) {
    this.set(MarketingPage.SECTIONS_KEY, sections);
  }

  public get permalink(): string {
    return this.get(MarketingPage.PERMALINK_KEY);
  }

  public set permalink(permalink: string) {
    this.set(MarketingPage.PERMALINK_KEY, permalink);
  }
}

Parse.Object.registerSubclass(MarketingPage.CLASS_NAME, MarketingPage);