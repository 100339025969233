export class Role extends Parse.Role {
  public static readonly CLASS_NAME = '_Role';
  public static readonly NAME_KEY = 'name';
  public static readonly USERS_KEY = 'users';
  public static readonly DISPLAYED_NAME_KEY = 'displayedName';

  public static readonly ADMIN_ROLE = 'role_admin';
  public static readonly SUPER_ADMIN_ROLE = 'role_super_admin';
  public static readonly CUSTOMER_ROLE = 'role_user';
  public static readonly DELIVERY_ROLE = 'role_delivery';
  public static readonly TEAM_LEADER_ROLE = 'role_team_leader';
  public static readonly COUPON_DISTRIBUTOR_ROLE = 'role_coupon_distributor';

  constructor(acl: Parse.ACL) {
    super(Role.CLASS_NAME, acl);
  }

  public get name(): string {
    return this.get(Role.NAME_KEY);
  }

  public set name(name: string) {
    this.set(Role.NAME_KEY, name);
  }

  public get displayedName(): string {
    return this.get(Role.DISPLAYED_NAME_KEY);
  }

  public set displayedName(displayedName: string) {
    this.set(Role.DISPLAYED_NAME_KEY, displayedName);
  }

  public get users() {
    return this.relation(Role.USERS_KEY);
  }
}

Parse.Object.registerSubclass(Role.CLASS_NAME, Role);
