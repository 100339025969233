import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class OrderItemService {
  private _item: Observable<any>

  constructor() {

  }

  public item() {
    if (!this._item) {
      this._item = from(Parse.Cloud.run('order-item-find', { limit: 15 })).pipe(publishReplay(1)).pipe(refCount());
    }
    return this._item;
  }
}
