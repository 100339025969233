import { ParseQuery } from './parse-query';

import { UserQuery } from './user-query';
import { OrderStatusQuery } from './order-status-query';

import { Order, User, OrderItem, Product, ProductVariant } from '@shared/data';

export class OrderQuery extends ParseQuery<Order> {
  constructor(orderId?: string) {
    super(Order);

    if (orderId) {
      this.equalTo(Order.OBJECT_ID_KEY, orderId);
    }
  }

  public search(searchText: string) {
    let queries: Parse.Query[] = [];

    queries.push(new OrderQuery().searchKey(Order.ORDER_NUMBER_KEY, searchText));
    queries.push(new OrderQuery().matchesQuery(Order.USER_KEY, new UserQuery().search(searchText)));

    this.orQuery(queries);
    return this;
  }

  public current() {
    this.equalTo(Order.USER_KEY, User.current());
    return this;
  }

  public statusCode(code: string) {
    this.matchesQuery(Order.STATUS_KEY, new OrderStatusQuery().code(code));
    return this;
  }

  public statusCodes(codes: string[]) {
    this.matchesQuery(Order.STATUS_KEY, new OrderStatusQuery().codes(codes));
    return this;
  }

  public notStatusCodes(codes: string[]) {
    this.matchesQuery(Order.STATUS_KEY, new OrderStatusQuery().notCodes(codes));
    return this;
  }

  public includeItems() {
    this.include(Order.ITEMS_KEY)
        .include([Order.ITEMS_KEY, OrderItem.PRODUCT_KEY].join('.'))
        .include([Order.ITEMS_KEY, OrderItem.PRODUCT_KEY, Product.VARIANTS_KEY].join('.'))
        .include([Order.ITEMS_KEY, OrderItem.PRODUCT_KEY, Product.VARIANTS_KEY, ProductVariant.IMAGES_KEY].join('.'))
        .include([Order.ITEMS_KEY, OrderItem.PRODUCT_KEY, Product.DEFAULT_VARIANT_KEY].join('.'))
        .include([Order.ITEMS_KEY, OrderItem.PRODUCT_KEY, Product.DEFAULT_VARIANT_KEY, ProductVariant.IMAGES_KEY].join('.'))
    return this;
  }
}